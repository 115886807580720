// Otros Medios de Pago
form {
    width: 90%;
    max-width: 400px;
    min-height: 90vh;
    margin: 0 auto;
    .form-header {
        width: 100%;
        margin: 50px auto 25px;
        background-color: var(--color-claro);
        text-align: center;
        h1,
        h2 {
            color: var(--auf);
        }
    }
    .logos {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin: 0 0 15px 0;
        img {
            width: 40%;
            filter: grayscale(95%);
            transition: filter 0.5s ease-in-out;
            &.current {
                filter: grayscale(0%);
            }
        }
    }
    h3 {
        margin: 10px 0 0;
        padding: 10px 0 0;
        font-size: 15px;
        color: var(--auf);
        border-top: solid 1px #d0ecff;
    }
    label {
        display: block;
        color: black;
    }
    input {
        width: calc(100% - 10px);
        height: 35px;
        margin: 0 0 10px;
        padding: 0 0 0 10px;
        color: rgb(var(--osc));
        border: 1px solid var(--auf);
        border-radius: 2px;
        font-weight: 300;
    }
    select {
        // width: 30%;
        height: 37px;
        margin: 10px 0 10px;
        padding: 0 0 0 10px;
        color: rgb(var(--osc));
        border: 1px solid var(--auf);
        border-radius: 2px;
        font-size: 16px;
        font-weight: 300;
        font-size: 14px;
    }
    ::placeholder {
        font-size: 15px;
        font-weight: 400;
        // color: var(--placeholders);
        // color: red;
    }
    .document-info {
        display: flex;
        align-items: flex-start;
        select {
            width: auto;
            margin: 0 10px 0 0;
            option {
                color: var(--auf);
            }
        }
    }

    .custom-error {
        color: #b41c32;
        padding: 10px;
        background: #ffeeee;
        margin: 5px;
        border-radius: 5px;
        border: solid 1px #ffd7e0;
        text-align: center;
        font-size: 13px;
    }
    button {
        width: 100%;
        // max-width: 300px;
        display: block;
        height: 50px;
        font-size: 20px;
        margin: 10px auto 0;
        background-color: var(--auf);
        color: var(--color-claro);
        border: none;
        border-radius: 3px;
        opacity: 1;
        transition: opacity 0.5s ease-in-out 0.5s;
        cursor: pointer;
    }
    .submit-opm {
        margin: 10px auto 90px; 
    }
    .cannot-submit {
        opacity: 0.4;
        cursor: not-allowed;
    }
    .progress-bar {
        width: 100%;
        padding: 5px;
    }
}

.checkout {
    margin: 40px 0 0;
    width: 100%;
    min-height: calc(100vh - 150px);
    background-color: var(--dark-color);
    display: flex;
    justify-content: center;
    align-items: center;
    .checkout-contents {
        width: 90%;
        max-width: 500px;
        background-color: var(--color-claro);
        border-radius: 5px 5px 0 0;
        .form-header {
            margin: 0 auto 20px;
            padding: 15px;
            border-radius: 5px 5px 0 0;
            background-color: var(--auf);
            font-size: 20px;
            h1 {
                text-align: center;
                color: var(--color-claro);
            }
        }
        h2,
        p {
            text-align: center;
            color: var(--auf);
            font-size: 20px;
        }
        p {
            margin: 5px 0 20px;
        }
        button {
            margin: 15px auto 20px;
            padding: 10px;
            display: block;
            text-align: center;
            font-size: 20px;
            background-color: var(--auf);
            color: var(--color-claro);
            border: none;
            border-radius: 3px;
        }
    }
    .payment-failed {
        width: 90%;
        max-width: 500px;
        background-color: var(--color-claro);
        border-radius: 5px 5px 0 0;
        .form-header {
            margin: 0 auto 20px;
            padding: 15px;
            border-radius: 5px 5px 0 0;
            background-color: var(--ter);
            font-size: 12px;
            h1 {
                text-align: center;
                color: var(--color-claro);
            }
        }
        h2,
        p {
            text-align: center;
            color: var(--dark-color);
            font-size: 18px;
        }
        p {
            margin: 10px 10px 20px;
        }
        .buttons {
            width: 90%;
            margin: 0 auto 25px;
            display: flex;
            justify-content: space-between;
            button {
                width: 45%;
                padding: 10px;
                background-color: var(--auf);
                border: none;
                border-radius: 3px;
            }
            .go-to-biblioteca {
                background-color: var(--neu-osc);
            }
        }
    }
}
