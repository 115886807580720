:root {
    --size: 75px;
    --clr-bg: #272324;
    --clr1: #8abee0;
    --clr2: #f1f1f1;
    --clr3: #99ccee;
    --clr4: #90e3ee;
    --clr5: #88dfee;
}

.spinner {
    --animation-duration: 650ms;
    position: relative;
    margin: 0 auto;
    width: var(--size);
    height: var(--size);

    .spinner-item {
        position: absolute;
        width: var(--item-size);
        height: var(--item-size);
        top: calc(50% - var(--item-size) / 2);
        left: calc(50% - var(--item-size) / 2);
        border: 4px solid transparent;
        border-left: 4px solid var(--clr-spinner);
        border-right: 4px solid var(--clr-spinner);
        border-radius: 50%;
        animation: spinner2 var(--animation-duration) linear infinite;

        @keyframes spinner2 {
            to {
                transform: rotate(360deg);
            }
        }
    }

    .spinner-item:nth-of-type(1) {
        --item-size: var(--size);
        --clr-spinner: var(--clr1);
        border-top: 4px solid var(--clr1);
    }

    .spinner-item:nth-of-type(2) {
        --item-size: calc(var(--size) - 15px);
        --clr-spinner: var(--clr5);
        border-bottom: 4px solid var(--clr5);
    }

    .spinner-item:nth-of-type(3) {
        --item-size: calc(var(--size) - 30px);
        --clr-spinner: var(--clr3);
        border-top: 4px solid var(--clr3);
    }
}

.spinner:nth-child(3) {
    --animation-duration: 5000ms;
    position: relative;
    width: var(--size);
    height: var(--size);
    transform: rotate(45deg);

    .spinner-item {
        --item-size: calc(var(--size) / 2.5);
        position: absolute;
        width: var(--item-size);
        height: var(--item-size);
        border: 4px solid var(--clr-spinner);
    }

    .spinner-item:nth-child(1) {
        --clr-spinner: var(--clr1);
        top: 0;
        left: 0;
        animation: spinner3A var(--animation-duration) linear infinite;

        @keyframes spinner3A {
            0%,
            8.33%,
            16.66%,
            100% {
                transform: translate(0%, 0%);
            }

            24.99%,
            33.32%,
            41.65% {
                transform: translate(100%, 0%);
            }

            49.98%,
            58.31%,
            66.64% {
                transform: translate(100%, 100%);
            }

            74.97%,
            83.30%,
            91.63% {
                transform: translate(0%, 100%);
            }
        }
    }

    .spinner-item:nth-child(2) {
        --clr-spinner: var(--clr3);
        top: 0;
        left: var(--item-size);
        animation: spinner3B var(--animation-duration) linear infinite;

        @keyframes spinner3B {
            0%,
            8.33%,
            91.63%,
            100% {
                transform: translate(0%, 0%);
            }

            16.66%,
            24.99%,
            33.32% {
                transform: translate(0%, 100%);
            }

            41.65%,
            49.98%,
            58.31% {
                transform: translate(-100%, 100%);
            }

            66.64%,
            74.97%,
            83.30% {
                transform: translate(-100%, 0%);
            }
        }
    }

    .spinner-item:nth-child(3) {
        --clr-spinner: var(--clr5);
        top: var(--item-size);
        left: var(--item-size);
        animation: spinner3C var(--animation-duration) linear infinite;

        @keyframes spinner3C {
            0%,
            83.30%,
            91.63%,
            100% {
                transform: translate(0, 0);
            }

            8.33%,
            16.66%,
            24.99% {
                transform: translate(-100%, 0);
            }

            33.32%,
            41.65%,
            49.98% {
                transform: translate(-100%, -100%);
            }

            58.31%,
            66.64%,
            74.97% {
                transform: translate(0, -100%);
            }
        }
    }
}
