.browse {
    .header {
        width: 100%;
        position: relative;
        background-color: rgb(var(--osc));
        z-index: 2;
    }
    main {
        // Banner para comprar partidos PPV
        .ppv-banner {
            padding-bottom: 5px;
            background: rgb(0, 13, 122);
            background: linear-gradient(
                90deg,
                rgba(65 135 232) 0%,
                rgba(1, 11, 93, 1) 57%
            );
            p,
            span {
                font-family: system-ui;
                font-weight: 500;
                text-align: center;
            }
            p {
                padding: 10px 5%;
                font-size: 15px;
                text-transform: uppercase;
            }
            span {
                display: block;
                padding: 0px 5% 10px;
                font-size: 12px;
                text-transform: none;
            }
            .ppv-banner-button {
                width: 84px;
                margin: 0 auto 10px;
                padding: 5px;
                display: block;
                background: rgb(1, 113, 241);
                background: linear-gradient(
                    90deg,
                    rgba(1, 113, 241, 1) 0%,
                    rgba(7, 73, 176, 1) 94%
                );
                font-family: system-ui;
                font-size: 12px;
                text-transform: uppercase;
                border: none;
                border-radius: 16px;
                cursor: pointer;
            }
        }
        background-color: var(--dark-color);
        .destacado {
            animation: fadeIn 5s;
            width: 100%;
            height: 56.25vw;
            // margin: 40px 0;
            position: relative;
            .destacado-bottom-gradient {
                position: absolute;
                height: 57vw;
                width: 100vw;
                background: linear-gradient(
                    180deg,
                    rgba(5, 22, 38, 0) 0%,
                    rgba(5, 22, 37, 0) 44%,
                    rgba(5, 22, 38, 1) 95%
                );
            }
            img {
                width: 100%;
                vertical-align: top;
            }
        }
        .biblioteca {
            position: relative;
            top: -9vw;
            .nuevos {
                background-image: linear-gradient(
                    to bottom,
                    rgba(20, 20, 20, 0) 0,
                    rgba(20, 20, 20, 0.15) 15%,
                    rgba(20, 20, 20, 0.35) 29%,
                    rgba(20, 20, 20, 0.58) 44%,
                    #141414 68%,
                    #141414 100%
                );
                background-size: 100% 100%;
                background-position: 0 top;
                background-repeat: repeat-x;
                background-color: transparent;
                width: 100%;
                // height: 14.7vw;
                top: auto;
                bottom: -1px;
                opacity: 1;
                .swiper-row {
                    h2 {
                        // background: linear-gradient(
                        //   180deg,
                        //   rgba(20, 20, 20, 0) 0%,
                        //   rgba(20, 20, 20, 0.2) 35%,
                        //   rgba(20, 20, 20, 0.3) 50%,
                        //   rgba(20, 20, 20, 0.5) 75%,
                        //   rgba(20, 20, 20, 1) 100%
                        // );
                        // background: transparent; 
                        background: rgb(5,22,38);
                        background: linear-gradient(0deg, rgba(5,22,38,1) 0%, rgba(5,22,38,1) 10%, rgba(5,22,38,0.1153054971988795) 86%); 
                    }
                }
            }
            .nuevos.destacados {
                margin-top: 30px;
                // .swiper-row {
                //   h2 {
                //     // margin-top: 50px;
                //   }
                // }
            }
            .nuevos.nuevos-en-vivo {
                margin: 30px 0 -30px;
            }
            .swiper-row {
                h2 {
                    padding: 15px 4% 10px;
                    vertical-align: bottom;
                    line-height: 1.25vw;
                    font-size: 1.2rem;
                    font-family: Arial, Helvetica, sans-serif;
                    font-weight: 300; 
                    background: linear-gradient(0deg, rgba(5,22,38,1) 0%, rgba(5,22,38,0.40) 40%, rgba(5,22,38,0) 100%); 
                    a {
                        text-decoration: none;
                        font-family: Arial, Helvetica, sans-serif;
                    }
                }
                .swiper-container {
                    padding: 0 4%;
                    background-color: var(--dark-color);
                    z-index: 0;
                    overflow: visible;
                    .swiper-button-prev,
                    .swiper-button-next {
                        height: 100%;
                        width: 4%;
                        width: calc(4% - 5px);
                        top: 0;
                        bottom: 0;
                        margin: 0;
                        background-color: rgba(20, 20, 20, 0.5);
                        &::after {
                            color: var(--color-claro);
                            font-size: 4vw;
                            font-weight: bold;
                            transition: transform 0.1s ease-out;
                        }
                    }
                    .swiper-button-prev {
                        left: 0;
                        border-top-right-radius: 2px;
                        border-bottom-right-radius: 2px;
                    }
                    .swiper-button-next {
                        right: 0;
                        border-top-left-radius: 2px;
                        border-bottom-left-radius: 2px;
                    }
                    .thumbtile {
                        img {
                            width: 100%;
                            border-radius: 2px;
                        }
                        .thumbtile-info {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@import 'browse480';
@import 'browse768';
@import 'browseBigScreens';

.ring-container {
    position: relative;
}

.circle {
    width: 12px;
    height: 12px;
    background-color: #6fbaf2;
    border-radius: 50%;
    position: absolute;
    top: 6px;
    left: 6px;
}

.ringring {
    border: 3px solid #6fbaf2;
    border-radius: 30px;
    height: 18px;
    width: 18px;
    position: absolute;
    // left: 15px;
    // top: 15px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
}

@keyframes pulsate {
    0% {
        -webkit-transform: scale(0.1, 0.1);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(0.8, 0.8);
        opacity: 0;
    }
}

.loader-black {
    opacity: 0.5;
}

.loader-high-container {
    width: 100%;
    height: 100vh;
    position: relative;
}

.swiper-loader {
    position: relative;
    overflow: hidden;
}

@media screen and (min-width: 481px) {
    .swiper-loader {
        min-height: 140px;
    }
}

.top-video-loader {
    position: absolute;
    width: 100%;
    margin: 0;
    background: var(--dark-color);
    z-index: 9999;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.video-swipper-animation {
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 0.9;
    }
}

@media screen and (min-width: 769px) and (max-aspect-ratio: 9/5) { 
    // 
    .browse {
        main {
            .destacado {
                height: auto;
            }
        }
    }
}