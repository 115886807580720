.terms {
    background: var(--dark-color);
    min-height: 90vh;

    .inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 90px 20px;
        h2 {
            font-size: 22px;
            margin-bottom: 10px;
        }
        h3 {
            font-size: 18px;
            margin-top: 25px;
        }
        h1 {
            font-size: 18px;
            margin-top: 25px;
        }
        h2 {
            font-size: 22px;
            margin-bottom: 10px;
        }
        p {
            color: var(--neu-pri);
        }
    }
}

@media screen and (min-width: 481px) {
    // Magia de pepe aca
}

@media screen and (min-width: 769px) {
    // Magia de pepe aca
}
