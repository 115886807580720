.forgot {
    background: var(--dark-color);
    min-height: 90vh;
    text-align: center;

    .top-video-loader {
        left: 0;
        top: 0;
    }
    button:disabled {
        opacity: 0.4;
    }
    label {
        margin-top: 16px;
        margin-bottom: 6px;
    }
    &.error {
        &.form-pages .form {
            input {
                border: solid 2px #aa2222;
            }

            label {
                color: #aa7a22;
            }
        }
    }

    &.form-pages .inner .form {
        max-width: none;
        width: 100%;
    }

    .recovery-content {
        padding: 100px 0 0;
        h2 {
            color: var(--pri);
            margin: 10px 15px;
            font-size: 2.5rem;
            font-weight: normal;
        }
        p,
        a {
            color: var(--neu-pri);
            font-size: 1.5rem;
        }
    }

    .inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 90px 20px;

        .forgot-confirm {
            padding: 90px 20px 0;

            h2 {
                color: var(--pri);
                margin: 10px 15px;
                font-size: 2.5rem;
                font-weight: normal;
            }
        }

        h2 {
            font-size: 22px;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 18px;
            margin-top: 25px;
        }

        h1 {
            font-size: 18px;
            margin-top: 25px;
        }

        h2 {
            font-size: 22px;
            margin-bottom: 10px;
        }

        p,
        a {
            color: var(--neu-pri);
            font-size: 1.5rem;
        }
    }
}

@media screen and (min-width: 481px) {
    // Magia de pepe aca
}

@media screen and (min-width: 769px) {
    // Magia de pepe aca
}
