// Signin / SignUp

.signin,
.signup { 
    .header {
        background: none;
        background-color: var(--apf-sec); 
    }
    form {
        label {
            visibility: hidden;
        }
    }
    .forgot-password {
        width: 100%;
        margin: 10px 0;
        text-align: right;
        font-size: 1.2rem;
        line-height: 2.5rem;
        a {
            margin: 0 15px;
            color: rgb(var(--osc));
            text-decoration: none;
            text-align: right;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .no-account {
        width: 100%;
        margin: 30px 15px;
        font-size: 1.5rem;
        line-height: 2.5rem;
        color: rgb(var(--osc));
        a {
            color: var(--pri);
            text-decoration: none;
            &:hover {
                font-weight: bold;
                cursor: pointer;
            }
        }
    }
    .error {
        color: red;
        padding: 5px 0 0 20px;
    }
}

.signup .form button[disabled] {
    background-color: #cccccc66;
    color: #66666647;
}
