.navigation-button {
    margin: 0 auto 0 0;
    padding: 3px 5px;
    border-radius: 2px;
    text-decoration: none;
    background-color: var(--pri);
    border: 1px solid var(--pri);
    text-transform: uppercase;
}
.buttons-container {
    padding: 0 20px 35px 20px;
}
.next {
    float: right;
}

.video-back {
    &:hover {
        transform: scale(1.25);
        cursor: pointer;
    }
    font-size: 18px;
    margin: 1%;
    text-align: left;
    border: none;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    text-decoration: none;
    background-color: transparent;

    span {
        border-radius: 50%;
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 3px solid var(--color-claro);
        border-radius: 50%;
        margin-right: 5px;
        vertical-align: middle;

        &:after {
            content: '';
            display: block;
            margin-top: 5px;
            margin-left: 6px;
            width: 7px;
            height: 7px;
            border-top: 3px solid #fff;
            border-right: 3px solid #fff;
            transform: rotate(-135deg);
        }
    }
}

.no-results {
    font-size: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50vh;
    align-items: center;
}

@media screen and (min-width: 481px) {
    .video-back {
        zoom: 0.5;
    }
}

@media screen and (min-width: 769px) {
    .video-back {
        zoom: 1;
    }
}
