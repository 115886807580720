.ModalBackground {
    width: 100vw;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(20, 20, 20, 0.8);
    color: white;
    z-index: 3;
}
