.categoryPage {
    // background: var(--background);
    background: var(--dark-color);
    min-height: 90vh;
    .categoryVideoList {
        margin: 0px 10px 0;
        padding: 50px 0;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        align-items: center;
        .categoryThumbtile {
            width: 45%;
            margin: 10px 2.5%;
            img {
                width: 100%;
            }
            h3 {
                height: 10vw;
            }
        }
    }
}

@media screen and (min-width: 481px) {
    .categoryPage {
        .categoryVideoList {
            padding: 10vh 0;
            .categoryThumbtile {
                width: 32%;
                margin: 10px 0.66%;
                h3 {
                    height: 8vw;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@media screen and (min-width: 769px) {
    .categoryPage {
        .categoryVideoList {
            .categoryThumbtile {
                width: 23%;
                margin: 10px 1%;
                h3 {
                    height: 5vw;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

@media screen and (min-width: 1201px) {
    .categoryPage {
        .categoryVideoList {
            .categoryThumbtile {
                width: 18%;
                margin: 10px 1%;
                h3 {
                    height: 3vw;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
