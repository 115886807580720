:root {
    font-size: 10px;
    --neu-pri: #bec6c4;
    --neu-osc: #adcab8;
    --pri: #41b6e5;
    --osc: 51, 63, 72;
    --color-claro: #fff;
    --background: #141414;
    --placeholders: #999999;
    --dark-color: #051626;
    // --footer-text: #91b1ce;
    --footer-text: #d5d5d5;
    --auf: #209aed;

    --sec: #daaa00;
    --ter: #c8102e;
    --neu-sec: #c5b9ac;
    --neu-ter: #c5b783; 

    --apf-pri: #1a599e;
    --apf-sec: #e20714;
    --apf-ter: #e6aa00;
}
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&family=Ubuntu:wght@300;400;500;700&display=swap');

@font-face {
    font-family: "Aller";
    src: local("Aller"),
      url("../fonts/Aller_Rg.ttf") format("truetype");
    font-weight: normal;
  }

* {
    color: var(--color-claro);
    margin: 0;
    // font-family: 'Aller', 'Open Sans', sans-serif;
    font-family: 'Aller';
}

// import app variables
//pages
@import 'landingPage';
@import 'signIn';
@import 'myAccount';
@import 'browse';
@import 'category';
@import 'terms';
@import 'forgot';
@import 'payment';
@import 'paymentOpm';
//components
@import 'header';
@import 'footer';
@import 'forms';
@import 'videoModal';
@import 'modalVideoCard';
@import 'destacadoInfo';
@import 'videoList';
@import 'spinner';
@import 'success';
@import 'video';
